import("./bootstrap").catch((err) => console.error(err));

// import { loadRemoteEntry } from "@angular-architects/module-federation";

// // If we want to wait for bootstrapping get remote modules first
// Promise.all([
//   loadRemoteEntry({
//     type: "module",
//     remoteEntry: "http://localhost:3000/remoteEntry.js",
//   }),
// ])
//   .catch((err) => console.error("Error loading remote entries", err))
//   .then(() => import("./bootstrap"))
//   .catch((err) => console.error(err));
